<template>
  <div>
    <div class="footer">
      <div class="footer_content">
        <div class="footer_title">Connect with us</div>
        <div class="footer_text">
          Follow us on Social Media and Unlock Monthly Voucher Rewards!
        </div>
        <div class="footer_icon">
          <img src="@/assets/imgs/hr/footer_facebook.svg"
            @click="openNew('https://www.facebook.com/mccsglife/')" alt="" />
          <img src="@/assets/imgs/hr/footer_wx.svg" @click="openQrcode" alt="" />
          <img src="@/assets/imgs/hr/footer_ins.svg"
            @click="openNew('https://sg.linkedin.com/company/mccsingapore')" alt="" />
          <img src="@/assets/imgs/hr/footer-youtobe.svg"
            @click="openNew('https://www.youtube.com/@mcclandsingapore4107')" alt="" />
          <img src="@/assets/imgs/hr/footer_cam.svg"
            @click="openNew('https://www.instagram.com/mccsglife/')" alt="" />
        </div>
      </div>
    </div>
    <div style="width: 100%;background-color: #F6F6F8;">
      <div class="footer_bottom">
        <div>Copyright © 2023 MCC Singapore. All Rights Reserved</div>
        <div @click="toPage">Human Right Policy</div>
      </div>
    </div>

  </div>
</template>

<script>
import { minxin } from '@/mixin.js';
export default {
  mixins: [minxin],
  methods: {
    openQrcode() {
      this.$emit('openQrcode', true);
    },
    toPage() {
      // window.open('/hr/policy');
      this.$router.push('/hr/policy');
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  width: 1440px;
  margin: auto;
  height: auto;
  padding: 0px 170px 0px 170px;
  box-sizing: border-box;
  .footer_content {
    width: 100%;
    height: 300px;
    background-image: url('../../../assets/imgs/hr/animation-global-connect.svg');
    background-repeat: no-repeat;
    background-position: 100% 50%;
    padding-top: 83px;
    box-sizing: border-box;
    .footer_title {
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: -0.54px;
      background: var(
        --primary-linear,
        linear-gradient(90deg, #3574af 0%, #47adff 100%)
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .footer_text {
      margin-top: 16px;
      color: var(--mcc-dark, #707070);
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: 0.24px;
    }
    .footer_icon {
      margin-top: 32px;
      img {
        margin-right: 60px;
      }
      img:hover {
        opacity: 0.81;
      }
    }
  }
}
.footer_bottom {
  // margin-top: 65px;
  width: 1100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  color: var(--mcc-dark, #707070);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.18px;
  background: #f6f6f8;
  display: flex;
  justify-content: space-between;
  margin: auto;
}
</style>