<template>
  <div class="container">
    <div class="fullDIv">
      <div class="header">
        <img src="@/assets/imgs/mcc_logo.svg" />
        <span class="logo_text">MCC Singapore Enterprise</span>
        <div class="header_title">
          Exploring Growth and Success
          <br />
          with us
        </div>
        <div class="header_text">Our Employee Value Proposition</div>
        <div class="menu_container">
         
          <div class="header_menu">
            <evpTopSwiper v-if="evpList.length>0" :list="evpList"></evpTopSwiper>

          </div>

        </div>
      </div>
    </div>

    <div class="content">
      <div
        class="content_item"
        v-for="(item, i) in evpList"
        :key="i"
        :class="{ flex_right: (i + 1) % 2 == 0 }"
      >
        <div class="content_img">
          <el-carousel arrow="always" :autoplay="true">
            <el-carousel-item v-for="item in item.imagesList" :key="item.id">
              <img
                :class="{
                  'border-left': (i + 1) % 2 != 0,
                  'border-right': (i + 1) % 2 == 0,
                }"
                style="width: 100%; height: 100%;object-fit: contain;"
                :src="item.link"
              />
              <!-- <div
                style="width: 100%; height: 100%; background-color: aqua"
              ></div> -->
            </el-carousel-item>
          </el-carousel>
        </div>
        <div
          class="content_text"
          :class="{
            'border-left': (i + 1) % 2 == 0,
            'border-right': (i + 1) % 2 != 0,
          }"
        >
          <div class="tetx_title">{{ item.evp }}</div>
          <div class="text_content">
            <!-- {{ item.description }} -->
            <p v-html="item.description.replace(/\n/g, '<br>')"></p>
          </div>
        </div>
      </div>
    </div>
    <div class="blueBlock" style="height: 20px"></div>
    <Footer @openQrcode="openQrcode"></Footer>
    <div class="qrcodeImg" v-if="showQrcode">
      <div class="qrcode_header">
        <img
          src="@/assets/imgs/hr/icon-close.svg"
          @click="() => (showQrcode = !showQrcode)"
        />
      </div>
      <div class="qrcode_content">
        <img src="@/assets/imgs/hr/wxqrcode.svg" alt="" />
        <div class="qrcode_text">
          Scan QR code with WeChat
          <br />

          App to connect
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Footer from "./components/footer.vue";
import evpTopSwiper from "./components/evpTopSwiper.vue";
// import func from "vue-editor-bridge";
export default {
  components: {
    Footer,
    evpTopSwiper
    // TalentItem,
  },
  data() {
    return {
      scroll: 0,
      showQrcode: false,
      evpList: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    openQrcode(e) {
      console.log(e);
      this.showQrcode = e;
    },

    async getList() {
      console.log(this.$url.hr.evpList);
      let response = await this.$http.get(`${this.$url.hr.evpList}`);
      if (response.code == 200) {
        this.evpList = response.data.records;
      } else {
        this.evpList = [];
      }
      console.log(response);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  font-family: Arial;
  // background: var(
  //   --primary-linear,
  //   linear-gradient(90deg, #0055a5 0%, #47adff 100%)
  // );
  margin-top: -5.1rem;
  // padding-top: 89.2px;
  // padding-bottom: 72px;
  box-sizing: border-box;
  .fullDIv {
    background: var(
      --primary-linear,
      linear-gradient(90deg, #0055a5 0%, #47adff 100%)
    );
  }
  .header {
    width: 1100px;
    margin: auto;
    padding-top: 89.2px;
    text-align: left;
    padding-bottom: 72px;
    .logo_text {
      color: var(--mcc-grey, #aeb0b3);
      font-family: Arial;
      font-size: 18px;
      font-weight: 400;
      line-height: 100%;
      letter-spacing: -0.27px;
      margin-left: 24px;
    }
    .header_title {
      text-align: center;
      margin-top: 144.37px;
      color: #fff;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 43.2px */
      letter-spacing: -0.54px;
    }
    .header_text {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 72px;
      color: #fff;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 19.2px */
      letter-spacing: -0.24px;
      margin: auto;
    }
    .menu_container {
      position: relative;
      .menuLeft {
        position: absolute;
        left: 0px;
        top: 110px;
        z-index: 999;
      }
      .menuRight {
        position: absolute;
        right: 0px;
        top: 110px;
        z-index: 999;
      }
    }
    .header_menu {
      overflow-x: auto;
      white-space: nowrap;
      width: 100%;
      margin: auto;
      overflow: scroll;
      height: auto;
      margin-top: 42px;
      box-sizing: border-box;

      .menu_item {
        display: inline-block;
        width: 128px;
        height: 240px;
        margin-top: 24px;
        border-radius: 15px;
        background: rgba(255, 255, 255, 0.2);
        box-shadow: 6px 9px 20px 0px rgba(0, 0, 0, 0.1);
        margin-right: 16px;
      }
    }
  }
  .content {
    width: 1100px;
    margin: auto;
    margin-top: 72px;
    height: auto;

    .flex_right {
      flex-direction: row-reverse;
    }
    .content_item {
      width: 100%;
      height: 400px;
      background-color: #fff;
      display: flex;
      margin-bottom: 16px;
      .content_img {
        width: 550px;
        height: 100%;
        // background-color: #aeb0b3;
      }
      .border-left {
        border-radius: 15px 0px 0px 15px;
      }
      .border-right {
        border-radius: 0px 15px 15px 0px;
      }
      .content_text {
        width: 550px;
        height: 100%;
        background: var(--mcc-light-grey, #f6f6f8);
        text-align: left;
        padding: 80px 42px 0px 48px;
        box-sizing: border-box;
        word-break: break-word;
        overflow: hidden;

        .tetx_title {
          width: 100%;
          color: var(--mcc-primary-01, #0055a5);
          font-family: Arial;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%; /* 24px */
          letter-spacing: -0.3px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .text_content {
          width: 100%;
          color: #000;
          font-family: Arial;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 21px */
          letter-spacing: -0.21px;
          margin-top: 56px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 9;
          -webkit-box-orient: vertical;
        }
        // background-color: #0055a5;
      }
    }
  }
}
::-webkit-scrollbar {
  display: none;
}
::v-deep .el-carousel {
  height: 100%;
  .el-carousel__container {
    height: 100%;
  }
}
::v-deep .el-carousel__button {
  // background-color: red !important;
  height: 8px !important;
  width: 8px !important;
  border-radius: 50%;
}
::v-deep .is-active {
  .el-carousel__button {
    background-color: #47adff !important;
  }
}
.qrcodeImg {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  position: fixed;
  padding: 24px;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.9);
  .qrcode_header {
    width: 100%;
    text-align: right;
  }
  .qrcode_content {
    border-radius: 8px;
    margin: auto;
    width: 326px;
    text-align: center;
    height: auto;
    background-color: #ffffff;
    margin-top: calc(50vh - 300px);
    padding: 24px;
    box-sizing: border-box;

    .qrcode_text {
      background: var(
        --primary-linear,
        linear-gradient(90deg, #3574af 0%, #47adff 100%)
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-align: center;
      font-family: Arial;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 16.8px; /* 16.8px */
      letter-spacing: -0.21px;
      margin-top: 16px;
    }
  }
}
.blueBlock {
  width: 100%;
  background: var(
    --primary-linear,
    linear-gradient(90deg, #0055a5 0%, #47adff 100%)
  );
}
</style>