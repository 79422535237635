var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"fullDIv"},[_c('div',{staticClass:"header"},[_c('img',{attrs:{"src":require("@/assets/imgs/mcc_logo.svg")}}),_c('span',{staticClass:"logo_text"},[_vm._v("MCC Singapore Enterprise")]),_vm._m(0),_c('div',{staticClass:"header_text"},[_vm._v("Our Employee Value Proposition")]),_c('div',{staticClass:"menu_container"},[_c('div',{staticClass:"header_menu"},[(_vm.evpList.length>0)?_c('evpTopSwiper',{attrs:{"list":_vm.evpList}}):_vm._e()],1)])])]),_c('div',{staticClass:"content"},_vm._l((_vm.evpList),function(item,i){return _c('div',{key:i,staticClass:"content_item",class:{ flex_right: (i + 1) % 2 == 0 }},[_c('div',{staticClass:"content_img"},[_c('el-carousel',{attrs:{"arrow":"always","autoplay":true}},_vm._l((item.imagesList),function(item){return _c('el-carousel-item',{key:item.id},[_c('img',{class:{
                'border-left': (i + 1) % 2 != 0,
                'border-right': (i + 1) % 2 == 0,
              },staticStyle:{"width":"100%","height":"100%","object-fit":"contain"},attrs:{"src":item.link}})])}),1)],1),_c('div',{staticClass:"content_text",class:{
          'border-left': (i + 1) % 2 == 0,
          'border-right': (i + 1) % 2 != 0,
        }},[_c('div',{staticClass:"tetx_title"},[_vm._v(_vm._s(item.evp))]),_c('div',{staticClass:"text_content"},[_c('p',{domProps:{"innerHTML":_vm._s(item.description.replace(/\n/g, '<br>'))}})])])])}),0),_c('div',{staticClass:"blueBlock",staticStyle:{"height":"20px"}}),_c('Footer',{on:{"openQrcode":_vm.openQrcode}}),(_vm.showQrcode)?_c('div',{staticClass:"qrcodeImg"},[_c('div',{staticClass:"qrcode_header"},[_c('img',{attrs:{"src":require("@/assets/imgs/hr/icon-close.svg")},on:{"click":() => (_vm.showQrcode = !_vm.showQrcode)}})]),_vm._m(1)]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header_title"},[_vm._v(" Exploring Growth and Success "),_c('br'),_vm._v(" with us ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"qrcode_content"},[_c('img',{attrs:{"src":require("@/assets/imgs/hr/wxqrcode.svg"),"alt":""}}),_c('div',{staticClass:"qrcode_text"},[_vm._v(" Scan QR code with WeChat "),_c('br'),_vm._v(" App to connect ")])])
}]

export { render, staticRenderFns }