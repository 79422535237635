<template>
  <div class="swiper-container">
    <div class="swiper-wrapper">
      <div
        class="swiper-slide swiper-slide1"
        v-for="(item, i) in list"
        :key="i"
      >
        <div class="evpItem">
          <div class="evpimg">
            <img v-if="item.iconList.length>0" :src="item.iconList[0].link" alt="" />
          </div>
          <div class="evpTitle">
            {{ item.evp }}
          </div>
        </div>
      </div>
    </div>
    <!-- 如果需要分页器 -->
    <!-- <div class="swiper-pagination"></div> -->
  </div>
</template>

<script>
import Swiper from "swiper"; // 注意引入的是Swiper
import "swiper/css/swiper.min.css"; // 注意这里的引入

export default {
  name: "HelloWorld",
  props: {
    list: {
      type: [],
      default: [],
    },
  },
  mounted() {
    var mySwiper = new Swiper(".swiper-container", {
      loop: false,
      // 指示点
      pagination: {
        el: ".swiper-pagination",
        clickable: true /* 分页器点可以点击切换 */,
      },
      slidesPerView: 6.8,
      circular: true,
      direction: "horizontal",
      // 自动播放
      autoplay: {
        delay: 3500,
        disableOnInteraction: false,
      },
      // config...
    });
    console.log(mySwiper);
  },
};
</script>

<style lang="scss" scoped>
.swiper-container {
  width: 100%;
  height: 280px;
}
.swiper-slide {
  width: 143.571px !important;
  height: 240px;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 6px 9px 20px 0px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  margin-right: 16px;
}
::v-deep .swiper-pagination-bullet-active {
  background-color: #ffffff !important;
}
.evpItem {
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 38px 0px 24px 0px;
  box-sizing: border-box;


  .evpimg {
    width: 100%;
    height: 120px;
    text-align: center;
    img {
      width: 120px;
      height: 100%;
      object-fit: contain;
    }
  }
  .evpTitle {
    color: #fff;
    text-align: center;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 19.2px */
    letter-spacing: -0.24px;
    width: 100%;
    padding: 0px 8px;
    box-sizing: border-box;
    overflow: hidden;
    word-break: keep-all !important;
    white-space:normal;
    margin-top: 25px;
  }
}
</style>
